import React from 'react';
// Styles
import BannerComponentStyles from './styles';

const ComponentBanner = ({ data }) => {
  if (data.bannerImage && data.bannerImage.file) {
    var backgroundStyle = {
      backgroundImage: `url(${data.bannerImage.file.url})`,
      backgroundPosition: `${data.bannerImageFocusX}% ${data.bannerImageFocusY}%`,
      backgroundSize: data.backgroundWidth
        ? `${data.backgroundWidth}vw`
        : 'cover',
    };
  }

  return (
    <BannerComponentStyles className="component componentType_componentBanner">
      <div
        className={`clearfix component-banner component-background_${
          data.componentBackground
        } ${data.bannerImage ? 'has-image' : 'no-image'}`}
        id={data.hashId}
      >
        {data.bannerImage && data.bannerImage.file && (
          <div style={backgroundStyle} className="banner-image" />
        )}
        <div
          className={`banner-content ${
            data.alignX ? `alignx_${data.alignX}` : 'alignx_Center'
          } ${data.alignY ? `alignx_${data.alignY}` : 'alignx_Middle'}`}
        >
          <div className="space-above" />
          {data.bannerHeading && <h1>{data.bannerHeading}</h1>}
          {data.subheading && <p>{data.subheading}</p>}
          {data.buttons && (
            <div className="banner-buttons">
              {data.buttons.map((buttonLink, index) => (
                <a
                  key={index}
                  className={`component-button button-color_${buttonLink.buttonColor}`}
                  href={buttonLink.relativePathLink}
                  target={buttonLink.openInNewTab ? '_blank' : '_self'}
                >
                  {buttonLink.linkText}
                </a>
              ))}
            </div>
          )}
          <div className="space-below" />
        </div>
      </div>
    </BannerComponentStyles>
  );
};
export default ComponentBanner;
