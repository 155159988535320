import styled from 'styled-components';
import { theme } from '../../theme/theme-vars';

const BannerComponentStyles = styled.div`
  .component-banner {
    position: relative;

    .banner-image {
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .banner-content {
      padding: 60px 40px 30px;
      position: relative;
      z-index: 2;
      margin: 0 auto;
      max-width: ${theme.components.componentStandardWidthMax};
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      min-height: 450px;

      .space-above,
      .space-below {
        flex-grow: 1;
      }

      p {
        font-size: 18px;
        margin-top: 0;
        margin-bottom: 30px;
      }

      @media (min-width: ${theme.components.desktopWidthMin}) {
        h1,
        p {
          width: 50%;
          flex-grow: 0;

          &:empty {
            visibility: hidden;
          }

          @media (max-width: ${theme.components.phoneWidthMax}) {
            width: 100%;
          }
        }

        &.alignx_Left {
          text-align: left;

          h1,
          p {
            text-align: left !important;
            align-self: flex-start;
          }
        }
        &.alignx_MidLeft {
          text-align: left;
          width: 900px;

          h1,
          p {
            text-align: left !important;
            align-self: flex-start;
            width: 70% !important;
          }
        }

        &.alignx_Center {
          text-align: center;

          h1,
          p {
            text-align: center !important;
            align-self: center;
          }
        }

        &.alignx_Right {
          text-align: right;

          h1,
          p {
            text-align: right !important;
            align-self: flex-end;
          }
        }

        &.aligny_Top {
          .space-above {
            flex-grow: 0;
          }

          .space-below {
            flex-grow: 1;
          }
        }

        &.aligny_Middle {
          .space-above,
          .space-below {
            flex-grow: 1;
          }
        }

        &.aligny_Bottom {
          .space-above {
            flex-grow: 1;
          }

          .space-below {
            flex-grow: 0;
          }
        }
      }

      .banner-buttons {
        padding: 0;
        flex-grow: 0;
        @media (max-width: ${theme.components.desktopWidthMin}) {
          text-align: center;
        }

        @media (max-width: ${theme.components.phoneWidthMax}) {
          text-align: center;
        }

        a.component-button {
          margin-right: 20px;

          &:last-child {
            margin-right: auto;
          }

          @media (max-width: ${theme.components.phoneWidthMax}) {
            margin-left: auto;
            margin-right: auto;
            width: 90%;
            display: block;
            text-align: center;
          }
        }
      }
    }
  }
`;
export default BannerComponentStyles;
